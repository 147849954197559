<template>
  <div
    v-if="user"
    class="flex flex-col w-full border-0 border-b border-solid border-gray-200"
  >
    <div
      class="w-full flex justify-center py-2 border-0 border-b border-solid border-gray-200"
    >
      <img
        :src="$checkAvatar(user.image)"
        onerror="javascript:this.src='/no-avatar.png'"
        class="h-16 w-16 rounded-full border border-solid border-gray-100"
      />
    </div>

    <div class="grid gap-4 grid-cols-3 text-center px-5 py-5">
      <!-- FULL NAME -->
      <div class="flex flex-col">
        <label for="prenom" class="text-gray-800 text-base">Prénom-Nom</label>
        <div id="prenom" class="text-base text-blackdok font-EffraR">
          {{ `${user.firstName}  ${user.lastName}` }} {{ user.gender ? `( ${user.gender.charAt(0)} )` : '' }}</b>
        </div>
      </div>
      <!-- TELEPHONE -->
      <div class="flex flex-col">
        <label for="phone" class="text-gray-800 text-base"> Téléphone</label>
        <div id="phone" class="text-base text-blackdok font-EffraR">
          {{ user.mobile || "-" }}
        </div>
      </div>
      <!-- CNSS  -->
      <div class="flex flex-col">
        <label for="CNSS" class="text-gray-800 text-base">
          N° d'affiliation CNSS
        </label>
        <div id="CNSS" class="text-base text-blackdok font-EffraR">
          {{ user.CNSS || "-" }}
        </div>
      </div>
      <!-- CIN -->
      <div class="flex flex-col">
        <label for="cin" class="text-gray-800 text-base"> CIN</label>
        <div id="cin" class="text-base text-blackdok font-EffraR">
          {{ user.cin || "-" }}
        </div>
      </div>
      <!-- Email  -->
      <div class="flex flex-col ">
        <label for="nom" class="text-gray-800 text-base">Email</label>
        <div id="nom" class="text-base text-blackdok font-EffraR">
          {{ !user.email || user.email.includes('@***.**') ? '-': user.email }}
        </div>
      </div>
      <!-- Nom Assurance  -->
      <div class="flex flex-col">
        <label for="assurance" class="text-gray-800 text-base">
          Assurance complémentaire</label
        >
        <div id="assurance" class="text-base text-blackdok">
          {{
            user.insurance && user.insurance.name ? user.insurance.name : "--"
          }}
        </div>
      </div>
      <!-- Date De naissance  -->
      <div class="flex flex-col ">
        <label for="datenaissance" class="text-gray-800 text-base">
          Date de naissance</label
        >
        <div id="datenaissance" class="text-base text-blackdok font-EffraR">
          {{ user.birthday | moment("DD/MM/YYYY") }} ({{
            formatAge(user.birthday)
          }})
        </div>
      </div>
      <!-- Address -->
      <div class="flex flex-col mb-5" v-if="user.landingPage">
        <label for="address" class="text-gray-800 text-base">Adresse</label>
        <div id="address" class="text-base text-blackdok font-EffraR">
          {{ user.landingPage.address ? user.landingPage.address.label : "-" }}
          <strong>{{
            user.landingPage.address && user.landingPage.address.city
              ? user.landingPage.address.city.name
              : "-"
          }}</strong>
        </div>
      </div>
      <!-- Numero de police  -->
      <div class="flex flex-col mb-5">
        <label for="police" class="text-gray-800 text-base">
          N° de police</label
        >
        <div id="police" class="text-base text-blackdok">
          {{
            user.insurance && user.insurance.numPolice
              ? user.insurance.numPolice
              : "--"
          }}
        </div>
      </div>
      <!-- Lieu de naissance  -->
      <div class="flex flex-col">
        <label for="Lieunaissance" class="text-gray-800 text-base">
          Lieu de naissance</label
        >
        <div id="Lieunaissance" class="text-base text-blackdok font-EffraR">
          {{ user.birthplace || "-" }}
        </div>
      </div>
      <!-- Job Profession  -->
      <div class="flex flex-col">
        <label for="Profession" class="text-gray-800 text-base">
          Profession</label
        >
        <div id="Profession" class="text-base text-blackdok font-EffraR">
          {{ user.job || "-" }}
        </div>
      </div>
      <!-- Numero adhésion  -->
      <div class="flex flex-col">
        <label for="adhesiob" class="text-gray-800 text-base">
          N° d'adhésion</label
        >
        <div id="adhesiob" class="text-base text-blackdok">
          {{
            user.insurance && user.insurance.numAdhesion
              ? user.insurance.numAdhesion
              : "--"
          }}
        </div>
      </div>
      <!-- Relationship  -->
      <div class="flex flex-col">
        <label for="etat" class="text-gray-800 text-base"> Etat civil</label>
        <div id="etat" class="text-base text-blackdok font-EffraR">
          {{ $FaSituation(user.relationship) || "-" }}
        </div>
      </div>
      <!-- languages  -->
      <div class="flex flex-col" v-if="user.landingPage">
        <label for="langs" class="text-gray-800 text-base">
          Langues parlées</label
        >
        <div id="langs" class="text-base text-blackdok font-EffraR">
          {{
            user.landingPage.languages.length
              ? user.landingPage.languages.join(", ")
              : "-"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    user: {
      type: Object,
      required: false
    }
  },
  methods: {
    formatAge: function(dateString) {
      var now = moment();
      var birth = moment(dateString);
      var age = moment.duration(now.diff(birth));
      const year = age.years();
      const months = age.months();
      const days = age.days();
      if(year > 2) {
        return year+" ans";
      } else if(year > 0){
        return year+" ans et " +months+" mois";
      } else if(months > 11) {
        return months+" mois";
      } else if(months > 0) {
        return months+" mois et "+ days +" jours";
      } else {
        return days +" jours";
      }
      // return moment().diff(dateString, "years", false);
    },
    moment() {
      return moment;
    }
  }
};
</script>

<style>
</style>
